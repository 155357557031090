import React, { useRef, PropTypes, Component } from "react";
import { Card, InputGroup, InputGroupAddon, Input } from "reactstrap";
import $ from "jquery";
import { getEquipments } from "../../App/Api/equipment.js";

import Post from "../../Common/Post";
import Category from "../../Common/Category";
import "./dashboard.css";
import Map from "./Map";
import classnames from "classnames";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 0,
      perPage: 20,
      searchKeyWord: null,
      sortOption: 0,
      filterOption: 0,
      city: 1,
      StartDate: null,
      EndDate: null,
      items: [],
      numOfPages: 0,
      activePage: 0,
      promoText: "",
      places: [],
      mapView: false,
      cityLat: null,
      cityLong: null,
    };
    this.searchRef = React.createRef();
    this.changeViewStatus = this.changeViewStatus.bind(this);
  }

  //TODO: get a list of all items and set state to them

  // componentWillReceiveProps() {
  //   if (this.props.location.state) {
  //     this.setState({ filterOption: this.props.location.state.filters });
  //     this.setState({ sortOption: this.props.location.state.sorts });
  //   }
  // }

  changeViewStatus(type) {
    if (type === 1) {
      this.setState({
        mapView: true,
      });
    } else {
      this.setState({
        mapView: false,
      });
    }
  }

  componentDidMount() {
    // console.log("CHECK POST");
    // console.log(this.props.location.state);
    const vm = this;
    let searckkeywords;
    if (this.props.location.state) {
      searckkeywords = this.props.location.state.searchWord;
    }
    let filterOptions;
    if (localStorage.getItem("filterId")) {
      filterOptions = JSON.parse(localStorage.getItem("filterId"));
      // console.log(filterOptions);
    } else {
      filterOptions = this.state.filterOption;
    }
    let sortOption;
    if (localStorage.getItem("sortId")) {
      sortOption = localStorage.getItem("sortId");
    } else {
      sortOption = 4;
    }
    let StartDate;
    let EndDate;
    if (
      localStorage.getItem("searchStartDate") &&
      localStorage.getItem("searchEndDate")
    ) {
      StartDate = localStorage.getItem("searchStartDate");
      EndDate = localStorage.getItem("searchEndDate");
    } else {
      StartDate = null;
      EndDate = null;
    }
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    // } else {
    //   city = "1";
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
    } else {
      province = "1";
    }

    getEquipments(
      vm.state.page,
      vm.state.perPage,
      vm.state.searchKeyWord,
      sortOption,
      filterOptions,
      province,
      StartDate,
      EndDate
    ).then(function (res) {
      // console.log("***** Equipment *****");
      // console.log(res);

      vm.setState({
        numOfPages: res.totalPages,
        places: res.items.map((mark) => {
          // console.log(mark);
          return {
            id: mark.id,
            name: mark.name,
            latitude: mark.pickupLocationLatitude,
            longitude: mark.pickupLocationLongitude,
            clusterImg: mark.equipmentMedias[0].filePath,
            mapFname: mark.user.firstName,
            mapLname: mark.user.lastName,
            mapMarkerCategory: mark.category.name,
            markerRating: mark.score,
            markerUserId: mark.user.id,
          };
        }),
      });
      // console.log("AFTER NEW ADDITION", vm.state.items);

      res.items.forEach((item) => {
        let obj = {};

        // console.log(item.pickupLocationLatitude, item.pickupLocationLongitude);
        obj.pickupLocationLatitude = item.pickupLocationLatitude;
        obj.pickupLocationLongitude = item.pickupLocationLongitude;

        // obj.name = item.name + " " + item.make + " " + item.model;
        obj.name = item.name;
        obj.user = item.user.firstName + " " + item.user.lastName;
        obj.rating = item.score;
        obj.userId = item.user.id;
        obj.id = item.id;
        obj.imageUrl = item.equipmentMedias[0].filePath;
        vm.setState(
          {
            items: vm.state.items.concat(obj),
          },
          () => {
            // console.log("AFTER SET STATE ADDITION", vm.state.items);
          }
        );
      });
    });
    if (this.searchRef.current) {
      this.searchRef.current.scrollIntoView({
        behavior: "smooth",
        top: "true",
      });
    }
    // $.ajax({
    //   method: "GET",
    //   dataType: "json",
    //   traditional: true,
    //   data: {},
    //   xhrFields: {
    //     withCredentials: true,
    //   },
    //   url: process.env.REACT_APP_API + "/settings/promo",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // }).then(function (res) {
    //   console.log("******** promo text ************");
    //   console.log(res.data);
    //   vm.setState({ promoText: res.data.content });
    // });
    // $.ajax({
    //   method: "GET",
    //   dataType: "json",
    //   traditional: true,
    //   data: {},
    //   xhrFields: {
    //     withCredentials: true,
    //   },
    //   url: `${process.env.REACT_APP_API}/cities/${city}`,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // }).then(function (res) {
    //   console.log("******** CITY latlong ************");
    //   console.log(res.data);
    //   vm.setState(
    //     { cityLat: res.data.latitude, cityLong: res.data.longitude },
    //     () => {
    //       console.log("CHANGED LAT LONG", vm.state.cityLat, vm.state.cityLong);
    //     }
    //   );
    // });
  }
  componentWillReceiveProps(nextProp) {
    // console.log("here");
    // console.log("NEXT UP", nextProp.location.state);

    this.setState({ mapView: false }, () => {
      this.handleSearch();
      if (nextProp.location.state) {
        this.setState({
          searchKeyWord: nextProp.location.state.searchWord,
          filterOption: nextProp.location.state.filters,
        });
      } else {
        this.setState({ searchKeyWord: "" });
      }
    });
  }
  refreshPage() {
    window.location.reload();
  }
  changeActivePage = (index) => {
    // console.log(index);
    this.setState({ activePage: index }, () => {
      this.handleSearch();
    });
  };

  backPage = () => {
    if (this.state.activePage >= 1) {
      this.setState({ activePage: this.state.activePage - 1 }, () => {
        this.handleSearch();
      });
    }
  };

  forwardPage = () => {
    if (this.state.activePage + 1 < this.state.numOfPages) {
      this.setState({ activePage: this.state.activePage + 1 }, () => {
        this.handleSearch();
      });
    }
  };
  handleSearch(e = null) {
    if (e) {
      e.preventDefault();
    }
    let filterOptions;
    if (localStorage.getItem("filterId")) {
      filterOptions = JSON.parse(localStorage.getItem("filterId"));
    } else {
      filterOptions = JSON.stringify(this.state.filterOption);
    }
    let sortOption;
    if (localStorage.getItem("sortId")) {
      sortOption = localStorage.getItem("sortId");
    } else {
      sortOption = 4;
    }
    let StartDate;
    let EndDate;
    if (
      localStorage.getItem("searchStartDate") &&
      localStorage.getItem("searchEndDate")
    ) {
      StartDate = localStorage.getItem("searchStartDate");
      EndDate = localStorage.getItem("searchEndDate");
    } else {
      StartDate = null;
      EndDate = null;
    }
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    // } else {
    //   city = "1";
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
    } else {
      province = "1";
    }

    const vm = this;
    // ajax call to city lat long /api/cities/{city

    // $.ajax({
    //   method: "GET",
    //   dataType: "json",
    //   traditional: true,
    //   data: {},
    //   xhrFields: {
    //     withCredentials: true,
    //   },
    //   url: `${process.env.REACT_APP_API}/cities/${city}`,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // }).then(function (res) {
    //   console.log("******** CITY latlong ************");
    //   console.log(res.data);
    //   vm.setState(
    //     { cityLat: res.data.latitude, cityLong: res.data.longitude },
    //     () => {
    //       console.log(
    //         "UPDATED CHANGE LAT LONG",
    //         vm.state.cityLat,
    //         vm.state.cityLong
    //       );
    //     }
    //   );
    // });

    this.setState({ items: [] }, () => {
      getEquipments(
        vm.state.activePage,
        vm.state.perPage,
        vm.state.searchKeyWord,
        sortOption,
        filterOptions,
        province,
        StartDate,
        EndDate
      )
        .then(function (res) {
          // console.log("***** Equipment *****");
          // console.log(res);
          vm.setState({ numOfPages: res.totalPages });
          vm.setState({
            numOfPages: res.totalPages,
            places: res.items.map((mark) => {
              // console.log(mark);
              return {
                id: mark.id,
                name: mark.name,
                latitude: mark.pickupLocationLatitude,
                longitude: mark.pickupLocationLongitude,
                clusterImg: mark.equipmentMedias[0].filePath,
                mapFname: mark.user.firstName,
                mapLname: mark.user.lastName,
                mapMarkerCategory: mark.category.name,
                markerRating: mark.score,
                markerUserId: mark.user.id,
              };
            }),
          });
          res.items.forEach((item) => {
            let obj = {};
            // obj.name = item.name + " " + item.make + " " + item.model;
            obj.name = item.name;
            obj.user = item.user.firstName + " " + item.user.lastName;
            obj.rating = item.score;
            obj.userId = item.user.id;
            obj.id = item.id;

            obj.pickupLocationLatitude = item.pickupLocationLatitude;
            obj.pickupLocationLongitude = item.pickupLocationLongitude;

            obj.imageUrl = item.equipmentMedias[0].filePath;
            vm.setState({
              items: vm.state.items.concat(obj),
              filterOption: 0,
            });
          });
        })
        .catch(function (rez) {
          localStorage.removeItem("filterId");
          localStorage.removeItem("sortId");
          localStorage.removeItem("searchStartDate");
          localStorage.removeItem("searchEndDate");
          localStorage.removeItem("searchCity");
        });
    });
    if (this.searchRef.current) {
      this.searchRef.current.scrollIntoView({
        behavior: "smooth",
        top: "true",
      });
    }
  }

  //end of handle search

  categoryChange = (item) => {
    // console.log("DASCHBOARD ITEM", item);
    this.setState({ filterOption: item.categoryId }, () => {
      this.handleSearch();
    });
  };

  render() {
    // console.log(this.state.items);
    const Pagination = () => {
      let list = [];
      if (this.state.numOfPages > 0) {
        list.push(
          <a
            className={this.state.activePage <= 0 ? "disabled-button" : null}
            onClick={() => this.backPage()}
          >
            &laquo;
          </a>
        );
        for (let i = 0; i < this.state.numOfPages; i++) {
          list.push(
            <a
              style={
                this.state.activePage == i
                  ? { backgroundColor: "#8AC53F", color: "white" }
                  : null
              }
              onClick={() => this.changeActivePage(i)}
            >
              {i + 1}
            </a>
          );
        }
        list.push(
          <a
            className={
              this.state.activePage + 1 >= this.state.numOfPages
                ? "disabled-button"
                : null
            }
            onClick={() => this.forwardPage()}
          >
            &raquo;
          </a>
        );
      }
      return list;
    };

    return (
      <React.Fragment>
        <div style={{ margin: "0 -15px" }} className="banner">
          <form
            className="banner-search-bar"
            style={{ zIndex: "5" }}
            onSubmit={(e) => this.handleSearch(e)}
          >
            <h1
              style={{
                color: "#fff",
                textShadow: "3px 3px 5px rgba(0,0,0,0.5)",
                fontSize: "45px",
                textAlign: "center",
                marginBottom: "100px",
                zIndex: "10",
              }}
            >
              {this.state.promoText}
            </h1>
            <InputGroup className="form-search has-search">
              <InputGroupAddon addonType="prepend">
                <div className="form-control-feedback ">
                  <img
                    src={require("../../Common/./Header/Images/icon_search.svg")}
                    alt="search"
                  />
                </div>
              </InputGroupAddon>
              <Input
                style={{ border: "none" }}
                type="text"
                className="form-control mainsearchinput"
                onChange={(e) =>
                  this.setState({ searchKeyWord: e.target.value })
                }
                placeholder="Search City, or Keyword"
              />
              <button className="main-searchbutton">Search</button>
            </InputGroup>
          </form>
        </div>

        <div
          className="posts-in-dash-title"
          style={{ padding: 20, marginTop: 60 }}
        >
          <h2>Categories On Nubosh</h2>
        </div>

        <div className="container-categories">
          <Category sortFilter={this.categoryChange} />
        </div>

        <div
          className="container-categories"
          style={{ padding: 20, marginTop: 60 }}
        >
          <h2>Explore Rentals</h2>

          <div
            className="renting-nav dash-map-view-list-view"
            style={{ paddingLeft: 5, marginTop: 20 }}
          >
            <span
              onClick={() => this.changeViewStatus(0)}
              className={classnames({ active: this.state.mapView != true })}
            >
              List View
            </span>

            <span
              className={classnames({ active: this.state.mapView })}
              onClick={() => this.changeViewStatus(1)}
            >
              Map View
            </span>
          </div>
        </div>

        {this.state.mapView && (
          <div className="mapcluster-container" style={{ padding: 20 }}>
            <p>
              Map view displays listings that are available for pickup only.{" "}
            </p>

            {this.state.places.length > 0 ? (
              <Map
                zoom={12}
                cityLat={this.state.cityLat}
                cityLong={this.state.cityLong}
                places={this.state.places}
              />
            ) : (
              <div className="no-results-div">
                <span className="no-results-span">
                  No Results found, please try another city or filter.
                </span>
              </div>
            )}
          </div>
        )}

        {!this.state.mapView && (
          <div>
            <div
              ref={this.searchRef}
              className="posts-in-dash-title"
              style={{ padding: 20, marginTop: 0 }}
            ></div>

            {this.state.items.length > 0 ? (
              this.state.items.map((item, index) => {
                // console.log(index);
                return (
                  <div className="posts-in-dash">
                    <Post
                      postName={item.name}
                      postUser={item.user}
                      postRating={item.rating}
                      postId={item.id}
                      postUserId={item.userId}
                      postImageUrl={item.imageUrl}
                    />
                  </div>
                );
              })
            ) : (
              <div className="no-results-div">
                <span className="no-results-span">
                  No Results found, please try another search term.{" "}
                </span>{" "}
                <span className="clickrefresh" onClick={this.refreshPage}>
                  {" "}
                  Or click here to see all the equipments
                </span>
              </div>
            )}
          </div>
        )}

        <div className="paginate">
          <Pagination />
        </div>
      </React.Fragment>
    );
  }
}
export default Dashboard;
