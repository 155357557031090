import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./post.css";
class RentingPost extends React.Component {
  constructor() {
    super();
  }

  render() {
    // let Rating = () => {
    //   let stars = []
    //   for(let i = 0; i < this.props.postRating; i++){
    //     stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />)
    //   }
    //   return stars;
    // }

    return (
      <Link
        to={{
          pathname: `/rentals/${this.props.RentingpostId}`,
          state: { postUserId: this.props.RentingpostUserId },
        }}>
        <Col sm="12" md="6" lg="4">
          <Card sm="12" className="dashboard-card">
            <CardHeader className="card-img-header p-0">
              <div className="card-img">
                <img
                  width="100%"
                  src={this.props.RentingpostImageUrl}
                  alt="item img"
                />
              </div>
            </CardHeader>
            <CardBody className="text-muted dashboard-card-body">
              <CardTitle className="renting-card-title ">
                <span className="title-rentingout">
                  {this.props.RentingpostName}{" "}
                </span>
                {/* <span style={{color:'red',float:'right'}}>Renting</span> */}
                {this.props.confirmedAt && (
                  <span
                    className="renting-status"
                    style={{ color: "#8AC53F", float: "right" }}>
                    Completed
                  </span>
                )}
                {!this.props.confirmedAt && !this.props.refundedAt ? (
                  <span
                    className="renting-status"
                    style={{ color: "red", float: "right" }}>
                    Active
                  </span>
                ) : null}
                {this.props.refundedAt && (
                  <span
                    className="renting-status"
                    style={{ color: "rgba(0,0,0,0.6)", float: "right" }}>
                    Canceled
                  </span>
                )}
              </CardTitle>

              <CardText className="dashboard-card-text">
                <div className="rentingout-pf-section-container">
                  <div className="first">
                    <img
                      width="100%"
                      src={this.props.RentingpostUserProfileImageUrl}
                      alt="item img"
                    />
                  </div>

                  <div className="second">
                    <div>
                      {this.props.RentingpostUser}
                      {/* {this.props.RentingpostUserId}  */}
                    </div>
                  </div>
                </div>

                <div className="">
                  <p>
                    Start Date:{" "}
                    <span style={{ color: "#000", fontWeight: 500 }}>
                      {this.props.startDate}
                    </span>
                  </p>
                  {this.props.openEndedRentalStatus === 0 ||
                  this.props.confirmedAt !== null ? (
                    <p>
                      End Date:{" "}
                      <span style={{ color: "#000", fontWeight: 500 }}>
                        {this.props.endDate}
                      </span>
                    </p>
                  ) : (
                    <p>Open ended</p>
                  )}
                  <p>
                    {this.props.type}:{" "}
                    <span style={{ color: "#8AC53F", fontWeight: 500 }}>
                      {this.props.pickUp.substring(
                        0,
                        this.props.pickUp.lastIndexOf(",")
                      )}
                    </span>
                  </p>
                  {/* <p>Delivary:  <span  style={{color:'#8AC53F',fontWeight:500}}>4119 Center street, NW Calgary, AB</span></p> */}
                  <p>
                    Price:{" "}
                    <span style={{ color: "#8AC53F", fontWeight: 500 }}>
                      $ {this.props.total}
                    </span>{" "}
                  </p>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Link>
    );
  }
}

RentingPost.propTypes = {
  RentingpostImageUrl: PropTypes.string,
  RentingpostName: PropTypes.string,
  RentingpostUser: PropTypes.string,
  RentingpostUserProfileImageUrl: PropTypes.string,
  RentingpostId: PropTypes.number,
  RentingpostUserId: PropTypes.number,
};

export default RentingPost;
