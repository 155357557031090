import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { Card, CardTitle, CardImg, CardBody } from "reactstrap";
import DateTime from "./DateTimePicker";
import moment from "moment";
import { getCategories } from "../../../App/Api/categories";
import { getStripeCheck } from "../../../App/Api/getStripeCheck";

import Switch from "react-switch";
// import SimpleMap from '../ViewPost/post-map';
import "./editPost.css";
import "../ViewPost/ViewPostTabs/style.css";

import $ from "jquery";

import PostSuccessModal from "../../../Common/Modals/PostSuccess";
import Posteditsuccessmodal from "../../../Common/Modals/PostEditSuccess";
import OnBoardModal from "../../../Common/Modals/OnBoradModal";

import Loading from "../../../Common/Loading";

import Map from "../EditPost/GoogleAutocomplete";
import "../EditPost/GoogleAutocomplete/map.css";
// import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
// import Autocomplete from 'react-google-autocomplete';
// import Geocode from "react-geocode";
import { Link } from "react-router-dom";

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);

    this.state = {
      PostSuccessModalOpen: false,
      PostEditSuccessModalOpen: false,
      checked: false,
      categorie: [],
      checked3: true,
      checked2: false,
      categoryId: 0,
      equipmentIds: [],
      addOnIds: [],
      equipmentMediasList: [], //should be uploadedMedia
      isAddOn: false,
      canBeRentedAsStandAlone: true,
      name: null,
      make: null,
      model: null,
      type: null,
      description: null,
      axles: 0,
      weight: 0,
      length: 0,
      year: null,
      sleeps: 0,
      dailyRate: 0,
      weeklyRate: 0,
      comments: null,
      startDate: null,

      startTime: null,

      endDate: null,

      endTime: null,

      isPickupAvailable: false,
      isOpenEndedRental: false,
      pickupLocationAddress: null,

      pickupLocationLatitude: 0,
      pickupLocationLongitude: 0,

      isDeliveryAvailable: false,
      deliveryLocationAddress: null,

      pickupLocationAddress_inc: null,
      pickupcity: null,
      pickuppostal: null,

      deliveryLocationLatitude: 0,
      deliveryLocationLongitude: 0,

      deliveryRateFirst10Km: 0,
      deliveryRateExtraPerKm: 0,
      imagefilepath: [],

      files: [],
      imagesPreviewUrls: [],

      videoFiles: [],
      videoPreviewUrls: [],

      uploadedMedia: [],

      uploadMediaErrors: "",

      are_any_images_uploaded: false,
      addOnDetails: [],

      selectedAddons: [],

      equipDetails: [],

      selectedEquips: [],
      fielderrors: false,
      dateErrors: false,
      apierrors: "",
      editingmyown: false,
      initialStartDateFromapi: null,
      initialEndDateFromapi: null,
      updatePickupAddress: false,
      updateDeliveryAddress: false,

      updateStartDateTime: false,
      updateEndDateTime: false,

      loading: false,
      rentalpdfname: "",
      stripeUrl: "",
      onboardModalOpen: false,
    };

    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getStripeCheck().then((res) => {
      console.log("RES FROM STRIPE CHECK", res);
      if (!res.isStripeConnectOnboarded) {
        this.setState({
          stripeUrl: res.stripeConnectOnboardLink,
          onboardModalOpen: true,
        });
      }
    });
  }
  checkStripeOnboard = () => {
    setInterval(() => {
      console.log("TEST TIMEOUT CHECK");
      getStripeCheck().then((res) => {
        if (res.isStripeConnectOnboarded) {
          this.setState({
            onboardModalOpen: false,
          });
        }
      });
    }, 3000);
  };

  handleChange(isPickupAvailable) {
    this.setState({ isPickupAvailable });
  }

  handleChange2(isDeliveryAvailable) {
    this.setState({ isDeliveryAvailable });
  }

  handleChange3(canBeRentedAsStandAlone) {
    this.setState({ canBeRentedAsStandAlone });
  }

  handleChange4(isOpenEndedRental) {
    this.setState({ isOpenEndedRental });
  }

  selectChangeid(e) {
    this.setState({
      categoryId: parseInt(e.target.value),
    });
  }

  // isanaddon() {
  //   this.setState({ isAddOn: true });
  // }

  toggle(type) {
    if (type == "postsuccessmodal") {
      this.setState((prevState) => ({
        PostSuccessModalOpen: !prevState.PostSuccessModalOpen,
      }));
    } else if (type == "posteditsuccessmodal") {
      this.setState((prevState) => ({
        PostEditSuccessModalOpen: !prevState.PostEditSuccessModalOpen,
      }));
    }
  }

  componentWillMount() {
    let vm = this;
    getCategories().then((res) => {
      console.log("THE CATEGORIES", res);
      vm.setState({ categorie: res.items });
    });
    if (this.props.postUserId) {
      console.log("EDIT ALREADY CREAATED POST");
      //TODO: get all states of fields
      //
      this.setState({ editingmyown: true });
      this.setState({ categoryId: null }, () => {
        this.getPostInfo();
      });
    } else {
      console.log("CREATE POST");

      if (this.props?.location?.state?.isAddOn) {
        this.getEquipments();
      } else {
        this.getAddons();
      }
      this.getRentalAgreement();
      this.setState({ isAddOn: this.props.location.state.isAddOn });
    }

    let data = {
      name: this.state.name,
    };
  }
  getRentalAgreement = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/users/me`,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.setState({ rentalpdfname: res.data.agreement.name });
    });
  };

  getPostInfo = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/equipments/${this.props.postId}`,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let mediaImage = [];
      let mediaVideo = [];
      res.data.equipmentMedias.forEach(function (media) {
        if (media.type == 0) {
          mediaImage.push(media.filePath);
        } else if (media.type == 2) {
          mediaVideo.push(media.filePath);
        }
      });

      let addonsIds = [];
      res.data.addOns.map((addon) => {
        addonsIds.push(addon.id);
      });
      let equipmentIds = [];
      res.data.equipments.map((addon) => {
        equipmentIds.push(addon.id);
      });
      vm.props.availability(res.data.isActive);

      console.log(
        "CAN BE RENTED AS STAND ALONE",
        res.data.canBeRentedAsStandAlone
      );
      let standalone;
      if (res.data.canBeRentedAsStandAlone) {
        standalone = res.data.canBeRentedAsStandAlone;
      }
      vm.setState(
        {
          canBeRentedAsStandAlone: standalone,
          isAddOn: res.data.isAddOn,
          selectedAddons: addonsIds,
          selectedEquips: equipmentIds,
          categoryId: res.data.category.id.toString(),
          name: res.data.name,
          make: res.data.make,
          model: res.data.model,
          type: res.data.type,
          description: res.data.description,
          axles: res.data.axles,
          weight: res.data.weight,
          length: res.data.length,
          year: res.data.year,
          sleeps: res.data.sleeps,
          dailyRate: res.data.dailyRate,
          weeklyRate: res.data.weeklyRate,
          startDate: res.data.startDate,
          endDate: res.data.endDate,
          startTime: res.data.startTime,
          endTime: res.data.endTime,
          comments: res.data.comments,
          isPickupAvailable: res.data.isPickupAvailable,
          isDeliveryAvailable: res.data.isDeliveryAvailable,
          isOpenEndedRental: res.data.isOpenEndedRental,
          imagesPreviewUrls: mediaImage,
          videoPreviewUrls: mediaVideo,
          pickupLocationAddress: res.data.pickupLocationAddress,
          pickupLocationLatitude: res.data.pickupLocationLatitude,
          pickupLocationLongitude: res.data.pickupLocationLongitude,
          deliveryLocationAddress: res.data.deliveryLocationAddress,
          deliveryLocationLatitude: res.data.deliveryLocationLatitude,
          deliveryLocationLongitude: res.data.deliveryLocationLongitude,
          deliveryRateFirst10Km: res.data.deliveryRateFirst10Km,
          deliveryRateExtraPerKm: res.data.deliveryRateExtraPerKm,
          uploadedMedia: res.data.equipmentMedias,
          rentalpdfname: res.data.user.agreement.name,
        },
        () => {
          console.log(vm.state);
          if (res.data.isAddOn) {
            vm.getEquipments();
          } else {
            vm.getAddons();
          }
        }
      );
      // if(res.data.isAddOn){
      //   vm.getEquipments()
      // }else{
      //   console.log("HERE!!!")
      //   vm.getAddons().then(function(){
      //     console.log("*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*_*")
      //     res.data.addOns.map(selectedAddon => {
      //       vm.state.addOnDetails.map(allAddons => {
      //         console.log("ADDONS")
      //         console.log(selectedAddon)
      //         console.log(allAddons)
      //         if(allAddons.id == selectedAddon.id){
      //           allAddons.selected = true
      //         }
      //       })
      //     })
      //   })
      // }

      // let addOnObj =  res.data.addOns.map(addOnElement => {
      //   return {
      //     addOnObjImg: addOnElement.equipmentMedias[0].filePath,
      //     addOnObjName: addOnElement.name,
      //     addOnObjDaily: addOnElement.dailyRate,
      //     addOnObjId: addOnElement.id,
      //   }
      // })
      // console.log("******** TEST ************");
      // console.log(addOnObj);
      // vm.setState({ userDetails: res.data.user,
      //               itemDetails: res.data,
      //               addOnDetails: addOnObj,
      //               itemDetailsId: res.data.id,

      // })
    });
    // });
  };

  _handlePostUpdate = (e) => {
    e.preventDefault();
    const vm = this;
    console.log(vm.state.selectedAddons);
    let startTime;
    let endTime;
    let endDate;
    let startDate;
    if (vm.state.updateStartDateTime) {
      startTime = vm.getTimeStringFromDateObject(vm.state.startDate);
      startDate = vm.state.startDate;
    } else {
      startDate = vm.state.startDate;
    }
    if (vm.state.updateEndDateTime) {
      endTime = vm.getTimeStringFromDateObject(vm.state.endDate);
      endDate = vm.adddateAndtime(vm.state.endDate, vm.state.endTime);
    } else {
      endDate = vm.state.endDate;
    }
    let updatedata = {
      categoryId: vm.state.categoryId,
      equipmentIds: vm.state.selectedEquips,
      addOnIds: vm.state.selectedAddons,
      equipmentMediasList: vm.state.uploadedMedia,
      isAddOn: vm.state.isAddOn,
      canBeRentedAsStandAlone: vm.state.canBeRentedAsStandAlone,
      name: vm.state.name,
      make: vm.state.make,
      model: vm.state.model,
      type: vm.state.type,
      description: vm.state.description,
      specifications: null,
      axles: vm.state.axles,
      weight: vm.state.weight,
      length: vm.state.length,
      year: vm.state.year,
      sleeps: vm.state.sleeps,
      dailyRate: vm.state.dailyRate,
      weeklyRate: vm.state.weeklyRate,
      comments: vm.state.comments,
      startDate: vm.state.startDate,
      startTime: startTime,
      endDate: vm.state.endDate,
      endTime: endTime,
      isPickupAvailable: vm.state.isPickupAvailable,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      pickupLocationAddress: vm.state.pickupLocationAddress,
      pickupLocationLatitude: vm.state.pickupLocationLatitude,
      pickupLocationLongitude: vm.state.pickupLocationLongitude,
      isDeliveryAvailable: vm.state.isDeliveryAvailable,
      deliveryLocationAddress: vm.state.deliveryLocationAddress,
      deliveryLocationLatitude: vm.state.deliveryLocationLatitude,
      deliveryLocationLongitude: vm.state.deliveryLocationLongitude,
      deliveryRateFirst10Km: vm.state.deliveryRateFirst10Km,
      deliveryRateExtraPerKm: vm.state.deliveryRateExtraPerKm,
    };
    if (
      vm.state.name != null &&
      vm.state.make != null &&
      vm.state.model != null &&
      vm.state.year != null &&
      vm.state.dailyRate != null &&
      vm.state.weeklyRate != null &&
      vm.state.startDate != null &&
      vm.state.endDate != null &&
      ((vm.state.isDeliveryAvailable &&
        vm.state.deliveryLocationAddress &&
        vm.state.deliveryLocationLatitude &&
        vm.state.deliveryLocationLongitude &&
        vm.state.deliveryRateExtraPerKm &&
        vm.state.deliveryRateFirst10Km) ||
        (vm.state.isPickupAvailable &&
          vm.state.pickupLocationAddress &&
          vm.state.pickupLocationLatitude &&
          vm.state.pickupLocationLongitude)) &&
      !vm.state.dateErrors &&
      vm.state.categoryId !== 0
    ) {
      $.ajax({
        method: "PUT",
        dataType: "json",
        data: JSON.stringify(updatedata),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: `${process.env.REACT_APP_API}/equipments/${vm.props.postId}`,
      })
        .then(function (res) {
          console.log("******** response from backend after ************");
          console.log(res.data);
          // vm.toggle('postsuccessmodal');
          vm.toggle("posteditsuccessmodal");
        })
        .catch(function (res) {
          console.log(res);
          if (res.responseJSON.code === "VALIDATION_ERROR") {
            vm.setState({
              apierrors: res.responseJSON.data.equipment[0],
            });
          } else if (res.responseJSON.code === "UNKNOWN_ERROR") {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          } else {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    }
  };

  getTimeStringFromDateObject(theDate) {
    if (!theDate || theDate == "") {
      return null;
    }
    let splitDate = theDate.split("T");
    let TimePart = splitDate[1];
    let TimePartClean = String(TimePart.substring(0, TimePart.length - 1));
    console.log("TimePartClean", TimePartClean);

    return TimePartClean;
  }

  _handleSubmit(e) {
    const vm = this;
    e.preventDefault();
    vm.setState({ loading: true });

    let startTime = vm.getTimeStringFromDateObject(vm.state.startDate);
    let endTime = vm.getTimeStringFromDateObject(vm.state.endDate);

    if (this.state.categoryId == 0) {
    } else {
    }
    let data = {
      categoryId: vm.state.categoryId,
      equipmentIds: vm.state.selectedEquips,
      addOnIds: vm.state.selectedAddons,
      equipmentMediasList: vm.state.uploadedMedia,
      isAddOn: vm.state.isAddOn,
      canBeRentedAsStandAlone: vm.state.canBeRentedAsStandAlone,
      name: vm.state.name,
      make: vm.state.make,
      model: vm.state.model,
      type: null,
      description: vm.state.description,
      specifications: null,
      axles: vm.state.axles ? vm.state.axles : 0,
      weight: vm.state.weight ? vm.state.weight : 0,
      length: vm.state.length ? vm.state.length : 0,
      year: vm.state.year,
      sleeps: vm.state.sleeps ? vm.state.sleeps : 0,
      dailyRate: vm.state.dailyRate,
      weeklyRate: vm.state.weeklyRate,
      comments: vm.state.comments,
      startDate: vm.state.startDate,
      startTime: startTime,
      endDate: vm.state.endDate,
      endTime: endTime,
      isPickupAvailable: vm.state.isPickupAvailable,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      pickupLocationAddress: vm.state.pickupLocationAddress,
      pickupLocationLatitude: vm.state.pickupLocationLatitude,
      pickupLocationLongitude: vm.state.pickupLocationLongitude,
      isDeliveryAvailable: vm.state.isDeliveryAvailable,
      deliveryLocationAddress: vm.state.deliveryLocationAddress,
      deliveryLocationLatitude: vm.state.deliveryLocationLatitude,
      deliveryLocationLongitude: vm.state.deliveryLocationLongitude,
      deliveryRateFirst10Km: vm.state.deliveryRateFirst10Km,
      deliveryRateExtraPerKm: vm.state.deliveryRateExtraPerKm,
    };
    console.log(JSON.stringify(data));

    // this.setState({uploadedMedia:this.state.equipmentMediasList})
    // this.setState({addOnIds:this.state.selectedAddons})

    // this.setState({pickupLocationAddress: this.state.pickupLocationAddress_inc + " " + this.sttaepickupcity + " " + this.statepickuppostal })
    console.log("THE STATE!!!!", vm.state);
    if (vm.state.are_any_images_uploaded === false) {
      vm.setState({ loading: false });
      alert("You must upload at least one image before submitting");
    } else if (
      vm.state.name != null &&
      vm.state.make != null &&
      vm.state.model != null &&
      vm.state.year != null &&
      vm.state.dailyRate != null &&
      vm.state.weeklyRate != null &&
      vm.state.startDate != null &&
      vm.state.endDate != null &&
      ((vm.state.isDeliveryAvailable &&
        vm.state.deliveryLocationAddress &&
        vm.state.deliveryLocationLatitude &&
        vm.state.deliveryLocationLongitude &&
        vm.state.deliveryRateExtraPerKm &&
        vm.state.deliveryRateFirst10Km) ||
        (vm.state.isPickupAvailable &&
          vm.state.pickupLocationAddress &&
          vm.state.pickupLocationLatitude &&
          vm.state.pickupLocationLongitude)) &&
      !vm.state.dateErrors &&
      vm.state.categoryId !== 0
    ) {
      console.log("data before sending ");
      console.log(data);

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/equipments",
      })
        .then(function (res) {
          console.log("******** response from backend after ************");
          console.log(res.data);
          vm.setState({ loading: false });
          vm.toggle("postsuccessmodal");
        })
        .catch(function (res) {
          console.log(res);
          if (res.code === "VALIDATION_ERROR" || res.code === "UNKNOWN_ERROR") {
            vm.setState({
              loading: false,
              apierrors:
                "The info you provided could not be validtaed. Please refresh the page and try again ",
            });
          } else {
            vm.setState({
              loading: false,
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    } else {
      vm.setState({ fielderrors: true, loading: false });
    }
  }

  handleUpload = () => {
    let vm = this;
    vm.setState({ loading: true });

    if (vm.state.files.length === 0) {
      alert("You need to select at least one image!");
    } else {
      $.when(vm.uploadImages()).done(function () {
        // $.when(vm.uploadVideos()).done(function() {
        vm.setState({ loading: false });
      });
      // });
    }
  };

  uploadImages = () => {
    const vm = this;

    let uploadedMedia = [];

    this.state.files.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("public", true);
      console.log(formData.get("image"));
      let data = {
        File: file,
        Public: true,
      };
      console.log("PASSING DATA");
      console.log(data);

      $.ajax({
        method: "POST",
        //dataType: 'json',
        processData: false,
        //traditional: true,
        contentType: false,
        data: formData,
        xhrFields: {
          withCredentials: true,
        },

        url: process.env.REACT_APP_API + "/uploads/uploadimage",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
        },
      })
        .then(function (res) {
          //if(res.code === "SUCCESS"){ }

          console.log("LENGTH", vm.state.uploadedMedia.length);
          let obj = {};
          if (uploadedMedia.length == 0 && vm.state.uploadedMedia.length == 0) {
            obj = {
              filePath: res.data.filePath,
              default: true,
            };
          } else {
            obj = {
              filePath: res.data.filePath,
              default: false,
            };
          }
          vm.setState({
            uploadedMedia: [...vm.state.uploadedMedia, obj],
            are_any_images_uploaded: true,
          });
          uploadedMedia.push(obj);
        })
        .catch(function (res) {
          if (res.responseJSON.code === "VALIDATION_ERROR") {
            vm.setState({
              apierrors: res.responseJSON.data.equipment[0],
            });
          } else if (res.responseJSON.code === "UNKNOWN_ERROR") {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          } else {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    });
  };

  _handleImageChange(e) {
    e.preventDefault();
    let vm = this;
    let files = Array.from(e.target.files);
    let size = 50000000;
    let approvedfiles = [];
    let fileCount = 0;

    if (files.length > 9) {
      alert(
        "Only 10 images can be uploaded to your equipment, please try again"
      );
      files.value = null; // discard selected file
    } else {
      files.forEach((file) => {
        console.log(file.size);
        fileCount++;

        if (file.size > size) {
          alert("One of the Images is bigger than 6mb. It will be omited.");
        } else {
          approvedfiles.push(file);
          let reader = new FileReader();
          reader.onloadend = () => {
            this.setState(
              {
                files: [...this.state.files, file],
                imagesPreviewUrls: [
                  ...this.state.imagesPreviewUrls,
                  reader.result,
                ],
              },
              () => {
                if (fileCount == vm.state.files.length) {
                  vm.handleUpload();
                }
              }
            );
          };
          reader.readAsDataURL(file);
        }
      });
    }

    console.log("the final images");
    console.log(this.state.files);
    console.log("the final images");
  }

  clear = (e) => {
    e.preventDefault();
    this.fileInput.value = [];
    this.setState(
      {
        files: [],
        imagesPreviewUrls: [],
        uploadedMedia: [],
      },
      () => {
        console.log("CLEARING", this.state);
      }
    );
  };

  // handleVideo = e => {
  //   e.preventDefault();
  //   let files = Array.from(e.target.files);
  //   console.log(files);
  //   let size = 50000000;
  //   files.forEach(file => {
  //     console.log(file.size);

  //     if (file.size > size) {
  //       alert("One of the Images is bigger than 6mb. It will be omited.");
  //     } else {
  //       let videoReader = new FileReader();
  //       videoReader.onloadend = () => {
  //         this.setState({
  //           videoFiles: [...this.state.videoFiles, file],
  //           videoPreviewUrls: [
  //             ...this.state.videoPreviewUrls,
  //             videoReader.result
  //           ]
  //         });
  //       };
  //       videoReader.readAsDataURL(file);
  //     }
  //   });
  //   // console.log('the final videos')
  //   // console.log(this.state.videoFiles)
  //   // console.log('the final videos')
  // };

  // clearvid = e => {
  //   e.preventDefault();
  //   this.setState({
  //     videoFiles: [],
  //     videoPreviewUrls: []
  //   });
  // };

  ChangeDate = (e) => {
    console.log("da date");
    let startdate_utc = moment.utc(e._d).format();
    console.log("startdate_utc", startdate_utc);
    this.setState(
      {
        startDate: startdate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  ChangeTime = (e) => {
    console.log("da time");
    console.log(e._d);
    let starttime_utc = moment.utc(e._d).format("HH:mm");
    console.log(starttime_utc);

    this.setState({
      startTime: String(starttime_utc),
    });
  };

  CheckDateLogic() {
    if (this.state.endDate && this.state.startDate) {
      let start = new Date(this.state.startDate);
      let end = new Date(this.state.endDate);
      if (start < end) {
        // start date is earlier then end date
        console.log("LOGIC TRUE");
        this.setState({ dateErrors: false });
      } else {
        // end date is earlier then start date
        this.setState({ dateErrors: true });
      }
    }
  }

  adddateAndtime(date, time) {
    console.log(time);
    let array = time.split(":");

    let hours = array[0];
    let min = array[1];
    let value = moment
      .utc(date)
      .add(hours, "hours")
      .add(min, "minutes")
      .format();
    //let value0 = moment(date).add(hours, 'hours').add(min, "minutes").format()
    console.log("finall-valuee", value);

    return value;
  }

  //ChangeDateEnd
  ChangeDateEnd = (e) => {
    console.log("da end date");
    let enddate_utc = moment.utc(e._d).format();
    console.log(enddate_utc);

    this.setState(
      {
        endDate: enddate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  ChangeTimeEnd = (e) => {
    console.log("da time");
    console.log(e);
    console.log(e._d);
    let endtime_utc = moment.utc(e._d).format("HH:mm");
    console.log(endtime_utc);

    this.setState({
      endTime: String(endtime_utc),
    });
  };

  getlatlong = (latLng) => {
    this.setState({
      pickupLocationLatitude: latLng.lat,
      pickupLocationLongitude: latLng.lng,
    });
  };

  getlatlongDeliver = (latLng) => {
    this.setState({
      deliveryLocationLatitude: latLng.lat,
      deliveryLocationLongitude: latLng.lng,
    });
  };

  theaddress = (add) => {
    this.setState({
      pickupLocationAddress: add,
    });
  };

  theaddressDeliver = (add) => {
    this.setState({
      deliveryLocationAddress: add,
    });
  };

  getAddons = () => {
    const vm = this;
    let data = {};

    // data.Page= 1;
    // data.PerPage= 10;
    data.EquipmentType = 1;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        console.log("******** add on data ************");
        console.log(res.data);

        let addOnObj = res.data.items.map((addOnElement) => {
          let selected = false;
          console.log("EDITTING", vm.state.selectedAddons);
          if (vm.state.editingmyown) {
            vm.state.selectedAddons.map((selectedAddon) => {
              if (selectedAddon === addOnElement.id) {
                console.log("FINISH!!!!");
                selected = true;
              } else {
                selected = false;
              }
            });
          }
          console.log("SELECTED", selected);
          return {
            addOnObjImg: addOnElement.equipmentMedias[0].filePath,
            addOnObjName: addOnElement.name,
            addOnObjDaily: addOnElement.dailyRate,
            addAddonID: addOnElement.id,
            selected: selected,
          };
        });
        console.log("******** TEST ************");
        console.log(addOnObj);
        vm.setState({
          addOnDetails: addOnObj,
        });
      })
      .catch(function (res) {
        console.log("the addon response ");
        console.log(res);
      });
  };

  getEquipments = () => {
    const vm = this;
    let data = {};

    // data.Page= 1;
    // data.PerPage= 10;
    data.EquipmentType = 2;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        console.log("******** eq data ************");
        console.log(res.data);

        let addOnObj = res.data.items.map((addOnElement) => {
          let selected = false;
          if (vm.state.editingmyown) {
            console.log("selectedequipments--here", vm.state.selectedEquips);
            vm.state.selectedEquips.map((selectedEquips) => {
              if (selectedEquips === addOnElement.id) {
                console.log("FINISH!!!!");
                selected = true;
              } else {
                selected = false;
              }
            });
          }

          return {
            addOnObjImg: addOnElement.equipmentMedias[0].filePath,
            addOnObjName: addOnElement.name,
            addOnObjDaily: addOnElement.dailyRate,
            addAddonID: addOnElement.id,
            selected: selected,
          };
        });

        vm.setState({
          equipDetails: addOnObj,
        });
      })
      .catch(function (res) {
        console.log("the addon response if issue ");
        console.log(res);
      });
  };

  addAddon = (elementID, index) => {
    console.log(elementID);
    console.log("addondetails", this.state.addOnDetails);
    let addons = this.state.addOnDetails;
    let selected = this.state.selectedAddons;

    addons[index].selected = !addons[index].selected;
    if (this.state.selectedAddons.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.selectedAddons.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ selectedAddons: selected });
  };

  addEquip = (elementID, index) => {
    console.log(elementID);
    let addons = this.state.equipDetails;
    let selected = this.state.selectedEquips;

    addons[index].selected = !addons[index].selected;
    if (this.state.selectedEquips.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.selectedEquips.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ selectedEquips: selected });
    // if (this.state.selectedEquips.length > 0) {
    //   this.state.selectedEquips.map(addon => {
    //     if (addon == elementID) {
    //       this.setState({
    //         selectedEquips: this.state.selectedEquips.filter(
    //           ele => ele !== elementID
    //         )
    //       });
    //     } else {
    //       this.setState(state => {
    //         const selectedEquips = [...state.selectedEquips, elementID];
    //         return selectedEquips;
    //       });
    //     }
    //   });
    // } else {
    //   this.setState({ selectedEquips: [elementID] });
    // }
  };

  updateThepickupAddress = () => {
    this.setState({ updatePickupAddress: true });
  };

  updateTheDeliveryAddress = () => {
    this.setState({ updateDeliveryAddress: true });
  };

  updateTheStartDateTime = () => {
    this.setState({ updateStartDateTime: true });
  };

  updateTheEndDateTime = () => {
    this.setState({ updateEndDateTime: true });
  };

  MakeTimePretty = (thetime) => {
    let startTime = thetime.split(":");

    let startHour = startTime[0];
    let test0 = moment
      .utc(this.state.startDate)
      .add(startHour, "hours")
      .format();
    // let test = moment( new Date(this.state.startDate)).add(startHour, "hours")
    // let test2= moment(this.state.startDate).add(startHour, 'hours').format('MMMM Do YYYY hh:mm a');
    let thefinal = moment(test0).format("MMM Do YYYY ,  hh:mm a");
    // let timeutc= moment.utc(test).format("dddd, MMMM Do YYYY hh:mm a")
    // let turntolocal= moment.utc(test2)
    // let finaltime= moment(turntolocal).format(" MMMM Do YYYY hh:mm a")
    //moment.utc(test).local().format("dddd, MMMM Do YYYY hh:mm a")
    console.log("time", test0);
    // console.log('fuck2', turntolocal)

    // let amorpm = startHour >12 ? "PM" : "AM"

    //let startMin = startTime[1]
    //let timeOfDaystart = test > 12 ? 'PM' : "AM"
    //let startTimeString = (test > 12 ? test - 12 : test) + ":" + startMin + " " + timeOfDaystart

    return thefinal;
  };

  MakeTimePretty2 = (thetime) => {
    let startTime = thetime.split(":");

    let startHour = startTime[0];
    let test0 = moment.utc(this.state.endDate).add(startHour, "hours").format();
    console.log("the start hr in end time", startHour);
    // let test = moment( new Date(this.state.startDate)).add(startHour, "hours")
    // let test2= moment(this.state.startDate).add(startHour, 'hours').format('MMMM Do YYYY hh:mm a');
    let thefinal = moment(test0).format("MMM Do YYYY ,  hh:mm a");
    let thefinal0 = moment(test0);
    // let timeutc= moment.utc(test).format("dddd, MMMM Do YYYY hh:mm a")
    // let turntolocal= moment.utc(test2)
    // let finaltime= moment(turntolocal).format(" MMMM Do YYYY hh:mm a")
    //moment.utc(test).local().format("dddd, MMMM Do YYYY hh:mm a")
    console.log("time", test0);
    console.log("moment added", thefinal0);
    // console.log('fuck2', turntolocal)

    // let amorpm = startHour >12 ? "PM" : "AM"

    //let startMin = startTime[1]
    //let timeOfDaystart = test > 12 ? 'PM' : "AM"
    //let startTimeString = (test > 12 ? test - 12 : test) + ":" + startMin + " " + timeOfDaystart

    return thefinal;
  };

  // can also set up so there is a form and defaultValue would be this.state.post.{itemName}
  render() {
    console.log("initial start time ", this.state.isOpenEndedRental);

    return (
      <Col>
        <PostSuccessModal
          toggle={this.toggle}
          modalOpen={this.state.PostSuccessModalOpen}
          backdrop="static"
        />
        <Posteditsuccessmodal
          toggle={this.toggle}
          modalOpen={this.state.PostEditSuccessModalOpen}
          backdrop="static"
        />
        {this.state.loading && <Loading modalOpen={this.state.loading} />}
        {this.state.onboardModalOpen && (
          <OnBoardModal
            startCheck={this.checkStripeOnboard}
            url={this.state.stripeUrl}
            modalOpen={this.state.onboardModalOpen}
          />
        )}
        <Row className="pt-5">
          <Col sm="7" className="image-upload-container">
            <form
              className="img-prev"
              style={{ width: "80%", margin: "0 auto" }}
              onSubmit={this._handleSubmit}>
              <input
                ref={(fileInput) => (this.fileInput = fileInput)}
                style={{ display: "none" }}
                className="upload"
                type="file"
                accept="image/*"
                onChange={this._handleImageChange}
                multiple
              />
              <div style={{ textAlign: "center", display: "flex" }}>
                <Button
                  style={{ width: "80%" }}
                  onClick={() => this.fileInput.click()}
                  className="upload-btn upload upload-images-btn py-3"
                  type="button">
                  Select Images of your{" "}
                  {this.state.isAddOn ? (
                    <span>Add on</span>
                  ) : (
                    <span>Equipment</span>
                  )}{" "}
                </Button>
                <Button
                  style={{ width: "20%", marginLeft: 10 }}
                  onClick={(e) => this.clear(e)}
                  type="button"
                  className="upload-reset-btn upload upload-images-btn py-3">
                  Clear
                </Button>
              </div>
              <div
                className="image-prev-container"
                style={{
                  textAlign: "center",
                  minHeight: "300px",
                  backgroundColor: "#EAF3EF",
                  padding: "50px 0",
                }}>
                {this.state.imagesPreviewUrls.length > 0 ? (
                  this.state.imagesPreviewUrls.map((imagePreviewUrl) => {
                    return (
                      <img
                        style={{ maxWidth: "300px", padding: "10px" }}
                        key={imagePreviewUrl}
                        alt="previewImg"
                        src={imagePreviewUrl}
                      />
                    );
                  })
                ) : (
                  <div>
                    <p>Image files allowed: png, jpg, jpeg</p>{" "}
                    <p>Please do not upload more than 10 images.</p>
                    <p>You must upload at least one image to create a post*.</p>
                  </div>
                )}
              </div>
            </form>

            <form
              className="img-prev"
              style={{ width: "80%", margin: "30px auto" }}
              onSubmit={this._handleSubmit}>
              <input
                onChange={this.handleVideo}
                ref={(fileInputvid) => (this.fileInputvid = fileInputvid)}
                type="file"
                name="file[]"
                className="file_multi_video"
                accept="video/*"
                style={{ display: "none" }}
                multiple
              />
              {/* <input ref={fileInput => this.fileInput = fileInput} style={{display:'none'}} className="upload" type="file" accept='image/*' onChange={this._handleImageChange} multiple /> */}
              {/* <div style={{ textAlign: "center", display: "flex" }}>
                <Button
                  style={{ width: "80%" }}
                  onClick={() => this.fileInputvid.click()}
                  className="upload-btn upload upload-images-btn py-3"
                  type="button"
                >
                  Select videos of your{" "}
                  {this.state.isAddOn ? (
                    <span>Add on</span>
                  ) : (
                    <span>Equipment</span>
                  )}
                </Button>
                <Button
                  onClick={e => this.clearvid(e)}
                  style={{ width: "20%", marginLeft: 10 }}
                  type="button"
                  className="upload-reset-btn upload upload-images-btn py-3"
                >
                  Clear
                </Button>
              </div> */}
              {/* <div
                className="image-prev-container"
                style={{
                  textAlign: "center",
                  minHeight: "300px",
                  backgroundColor: "#EAF3EF",
                  padding: "50px 0"
                }}
              >
                {this.state.videoPreviewUrls.length > 0 ? (
                  this.state.videoPreviewUrls.map(videoUrl => {
                    return (
                      <video
                        className="videoprew"
                        style={{ padding: "10px" }}
                        width="350"
                        controls
                      >
                        <source src={videoUrl} key={videoUrl} />
                        Your browser does not support HTML5 video.
                      </video>
                    );
                  })
                ) : (
                  <div>
                    <p>File types Allowed: mov, mp4, avi, ogg, and webm</p>{" "}
                    <p>Please do not upload videos bigger than 50Mb each.</p>
                  </div>
                )}

              </div> */}
            </form>

            <div
              className="upload-images-button-container"
              style={{ width: "100%", textAlign: "center" }}>
              {/* <Button
                className="upload-reset-btn upload upload-images-btn py-3"
                type="button"
                onClick={this.handleUpload}
              >
                {this.state.uploadedMedia.length > 0 ? (
                  <span> Files Uploaded!</span>
                ) : (
                  <span>Upload your selected images</span>
                )}{" "}
              </Button> */}
              {this.state.uploadMediaErrors.length > 0 ? (
                <p
                  style={{ color: "#000", fontWeight: "600", padding: "10px" }}
                  className="upload-media-errors">
                  {this.state.uploadMediaErrors}{" "}
                </p>
              ) : null}
            </div>

            {this.state.isAddOn === false ? (
              <div
                className="addons-section-creating-equip"
                style={{ marginTop: 150 }}>
                {this.state.addOnDetails.length !== 0 ? (
                  <Row style={{ padding: 10, width: "85%", margin: "0 auto" }}>
                    <div>
                      <h4>
                        Add Ons{" "}
                        <span style={{ fontWeight: "300" }}>(optional)</span>
                      </h4>
                      <p>
                        You can select an already created Add on to attach to
                        your equipment here.{" "}
                      </p>
                    </div>
                    <div
                      className="container post-info-container"
                      style={{ borderColor: "transparent" }}>
                      <div className="addons-side-right">
                        <div
                          className="addon-cards-container0 final-addon-container"
                          style={{ height: "400px" }}>
                          <div className="addon-cards-container">
                            {this.state.addOnDetails.map((addOnElement, i) => {
                              return (
                                <Card on style={{ minWidth: "300px" }}>
                                  <div className="add-on-img">
                                    {/* <CardHeader> */}
                                    <CardImg
                                      top
                                      width="100%"
                                      src={addOnElement.addOnObjImg}
                                      alt="Card image cap"
                                    />
                                    <span className="price-on-addons">
                                      ${addOnElement.addOnObjDaily}/day
                                    </span>
                                    {/* </CardHeader> */}
                                  </div>
                                  <CardBody>
                                    <CardTitle
                                      style={{
                                        maxWidth: "263px",
                                        overflow: "hidden",
                                      }}>
                                      {addOnElement.addOnObjName}
                                    </CardTitle>
                                    <button
                                      style={
                                        addOnElement.selected
                                          ? { backgroundColor: "#3E3F9B" }
                                          : { backgroundColor: "#8AC53F" }
                                      }
                                      type="button"
                                      onClick={() =>
                                        this.addAddon(
                                          addOnElement.addAddonID,
                                          i
                                        )
                                      }
                                      block
                                      className="add-ons-button-edit-post">
                                      {" "}
                                      {addOnElement.selected
                                        ? "Remove  "
                                        : "Attach this Add on "}
                                    </button>
                                  </CardBody>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div style={{ width: "85%", margin: "50px auto" }}>
                    <div style={{ padding: "30px" }}>
                      <h4>Add Ons</h4>
                      <p>
                        You Do not have any Add ons to select. You must first
                        create an add on before you can select one to add to
                        your equipment.{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{ marginTop: "150px" }}
                className="equip-section-creating-addon">
                {this.state.equipDetails.length !== 0 ? (
                  <Row style={{ padding: 10, width: "85%", margin: "0 auto" }}>
                    <div>
                      <h4>Attach to an Equipments (optional)</h4>{" "}
                      <p>
                        If this add on is an attachment to an existing
                        equipment, select it from here. If it is a standalone
                        add on, then skip this step.
                      </p>
                    </div>
                    <div
                      className="container post-info-container"
                      style={{ borderColor: "transparent" }}>
                      <div className="addons-side-right">
                        <div
                          className="addon-cards-container0"
                          style={{ width: "650px", height: "400px" }}>
                          <div className="addon-cards-container">
                            {this.state.equipDetails.map((addOnElement, i) => {
                              return (
                                <Card on style={{ minWidth: "300px" }} key={i}>
                                  <div className="add-on-img">
                                    <CardImg
                                      top
                                      width="100%"
                                      src={addOnElement.addOnObjImg}
                                      alt="Card image cap"
                                    />
                                    <span className="price-on-addons">
                                      ${addOnElement.addOnObjDaily}/day
                                    </span>
                                  </div>
                                  <CardBody>
                                    <CardTitle
                                      style={{
                                        maxWidth: "263px",
                                        overflow: "hidden",
                                      }}>
                                      {addOnElement.addOnObjName}
                                    </CardTitle>
                                    <button
                                      style={
                                        addOnElement.selected
                                          ? { backgroundColor: "#3E3F9B" }
                                          : { backgroundColor: "#8AC53F" }
                                      }
                                      type="button"
                                      onClick={() =>
                                        this.addEquip(
                                          addOnElement.addAddonID,
                                          i
                                        )
                                      }
                                      block
                                      className="add-ons-button-edit-post">
                                      {" "}
                                      {addOnElement.selected
                                        ? "Remove this Equipment "
                                        : "Attach Equipment to Add on"}
                                    </button>
                                  </CardBody>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                ) : (
                  <div style={{ width: "85%", margin: "50px auto" }}>
                    <div style={{ padding: "30px" }}>
                      <h4>Attach an Equipment</h4>
                      <p>
                        If this add on is an attachment to an existing
                        equipment, select it from here. If its an standalone add
                        on then skip this step.
                      </p>
                      <p style={{ color: "#717171" }}>
                        You Do not have equipments to select. You must first
                        create an equipment if you want to link it to your add
                        on{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Col sm="5">
            <Row>
              <Form className="edit-post">
                <FormGroup row>
                  {this.state.isAddOn === true ? (
                    <div style={{ margin: "20px 0" }}>
                      {" "}
                      <h5>Please provide details about your add on.</h5>
                      <p style={{ color: "rgba(0,0,0,0.4)" }}>
                        Fields marked * are required.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h5>Please provide details about your equipment.</h5>
                      <p style={{ color: "rgba(0,0,0,0.4)" }}>
                        Fields marked * are required.
                      </p>
                    </div>
                  )}
                </FormGroup>

                <FormGroup row>
                  <Col sm={6}>
                    <Label for="itemCategory">Category</Label>
                    <select
                      defaultValue={this.state.categoryId}
                      className="form-control"
                      name="category"
                      id="itemCategory"
                      onChange={(e) => this.selectChangeid(e)}>
                      <option hidden value="0">
                        Select a category
                      </option>
                      {this.state.categorie.map((cat, i) => {
                        return (
                          <option
                            key={i}
                            selected={
                              this.state.categoryId == cat.id
                                ? "selected"
                                : null
                            }
                            value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <Label for="itemName">Type*</Label>
                    <Input
                      value={this.state.name || ""}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      value={this.state.name}
                      type="text"
                      name="name"
                      id="itemName"
                      placeholder="Name"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={4}>
                    <Label for="itemMake">Make*</Label>
                    <Input
                      value={this.state.make}
                      onChange={(e) => this.setState({ make: e.target.value })}
                      type="text"
                      name="make"
                      id="itemMake"
                      placeholder="Make"
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="itemModel">Model*</Label>
                    <Input
                      value={this.state.model}
                      onChange={(e) => this.setState({ model: e.target.value })}
                      type="text"
                      name="model"
                      id="itemModel"
                      placeholder="Model"
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="itemType">Year*</Label>
                    <Input
                      value={this.state.year}
                      onChange={(e) =>
                        this.setState({ year: parseInt(e.target.value) })
                      }
                      type="number"
                      step="1"
                      name="year"
                      id="itemType"
                      placeholder="Type"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Label for="itemDescription">Description*</Label>
                    <Input
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      type="textarea"
                      name="description"
                      id="itemDescription"
                      placeholder="Tell us a little about your equipment here."
                    />
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Col sm={12}>
                    <h4>Specifications</h4>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="itemAxles">Axles</Label>
                    <Input
                      value={this.state.axles}
                      onChange={(e) =>
                        this.setState({ axles: parseInt(e.target.value) })
                      }
                      type="number"
                      name="axles"
                      id="itemAxles"
                      placeholder="enter a number"
                    />
                  </Col>
                  <Col sm={6}>
                    <Label for="itemWeight">Weight (lbs)</Label>
                    <Input
                      value={this.state.weight}
                      onChange={(e) =>
                        this.setState({ weight: parseFloat(e.target.value) })
                      }
                      type="number"
                      step="0.1"
                      name="weight"
                      id="itemWeight"
                      placeholder="enter a number"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="itemLength">Length (m)</Label>
                    <Input
                      value={this.state.length}
                      onChange={(e) =>
                        this.setState({ length: parseFloat(e.target.value) })
                      }
                      type="number"
                      step="0.1"
                      name="length"
                      id="itemLength"
                      placeholder="enter a number"
                    />
                  </Col>
                  <Col sm={6}>
                    <Label for="itemSleeps">Sleeps</Label>
                    <Input
                      value={this.state.sleeps}
                      onChange={(e) =>
                        this.setState({ sleeps: parseInt(e.target.value) })
                      }
                      type="number"
                      step="1"
                      name="sleeps"
                      id="itemSleeps"
                      placeholder="enter a number"
                    />
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Col>
                    <h4>Rates</h4>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="itemDailyRate">Daily Rate*</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input
                        value={this.state.dailyRate}
                        onChange={(e) =>
                          this.setState({
                            dailyRate: parseFloat(e.target.value),
                          })
                        }
                        min={0}
                        type="number"
                        step="0.1"
                        name="dailyRate"
                        id="itemDailyRate"
                      />
                      {/* <InputGroupAddon addonType="append">.00</InputGroupAddon> */}
                    </InputGroup>
                  </Col>
                  <Col sm={6}>
                    <Label for="itemDailyRate">Weekly Rate*</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input
                        value={this.state.weeklyRate}
                        onChange={(e) =>
                          this.setState({
                            weeklyRate: parseFloat(e.target.value),
                          })
                        }
                        min={0}
                        type="number"
                        step="0.1"
                        name="dailyRate"
                        id="itemDailyRate"
                      />
                      {/* <InputGroupAddon addonType="append">.00</InputGroupAddon> */}
                    </InputGroup>
                  </Col>
                </FormGroup>

                {this.state.isAddOn === true ? (
                  <span>
                    <hr />
                    <FormGroup row>
                      <Col sm={12} className="rentedbyitself">
                        <Col sm={7} className="pl-0">
                          <h6
                            className="post-title"
                            style={{ paddingTop: "10px" }}>
                            This Add on can be rented by itself{" "}
                          </h6>
                        </Col>
                        <Col
                          sm={5}
                          className="d-flex flex-row justify-content-between">
                          <Label for=""> </Label>
                          <label>
                            <Switch
                              onChange={this.handleChange3}
                              checked={this.state.canBeRentedAsStandAlone}
                              offColor="#E6E6E6"
                              onColor="#D0E7B2"
                              onHandleColor="#8AC53F"
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={20}
                              width={48}
                            />
                          </label>
                        </Col>
                      </Col>
                    </FormGroup>
                  </span>
                ) : null}

                <hr />
                <FormGroup row>
                  <Col>
                    <h4>Rental Availability</h4>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Col sm={7} className="pl-0">
                      <h4 className="post-title">Open ended rental </h4>
                    </Col>
                    <Col
                      sm={5}
                      className="d-flex flex-row justify-content-between">
                      <Label for=""> </Label>
                      <label>
                        <Switch
                          onChange={this.handleChange4}
                          checked={this.state.isOpenEndedRental}
                          offColor="#E6E6E6"
                          onColor="#D0E7B2"
                          onHandleColor="#8AC53F"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                        />
                      </label>
                    </Col>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <Label for="itemStartTime">
                      <span style={{ color: "#000" }}>Start Time*</span>
                    </Label>
                    <span style={{ display: "block", color: "#AAAAAA" }}>
                      {" "}
                      ( When is the item available )
                    </span>
                  </Col>
                  <Col sm={6} className="timedatepicker-cont">
                    {/* <DateTime type="date"  onChange={(e) => (this.setState({startDate: e.target.value}))} /> */}
                    {this.state.editingmyown &&
                    !this.state.updateStartDateTime ? (
                      <div>
                        {" "}
                        <div className="old-startdatetime-displayed">
                          {" "}
                          <p style={{ color: "#8AC53F" }}>
                            {" "}
                            <span>
                              {this.state.startTime &&
                                this.MakeTimePretty(this.state.startTime)}
                            </span>{" "}
                            <span style={{ marginLeft: 10 }}>
                              {" "}
                              <button
                                className="change-button"
                                type="button"
                                onClick={this.updateTheStartDateTime}>
                                Change
                              </button>
                            </span>
                          </p>
                        </div>
                        {this.state.updateStartDateTime ? (
                          <div className="good-ol-old-time-date-selector">
                            <DateTime
                              minDate={moment().toDate()}
                              onChange={this.ChangeDate}
                            />
                            {/* <DateTime type="time"  onChange={this.ChangeTime} />  */}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="create-post-datetime">
                        <DateTime
                          minDate={moment().toDate()}
                          onChange={this.ChangeDate}
                        />
                        {/* <DateTime type="time"  onChange={this.ChangeTime} />  */}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginTop: "40px" }}>
                  <Col sm={6}>
                    <Label for="itemEndTime">
                      <span style={{ color: "#000" }}>End Time*</span>
                    </Label>
                    <span style={{ display: "block", color: "#AAAAAA" }}>
                      {" "}
                      (When do you need the item back)
                    </span>
                  </Col>

                  <Col sm={6} className="timedatepicker-cont">
                    {this.state.editingmyown &&
                    !this.state.updateEndDateTime ? (
                      <div>
                        {" "}
                        <div className="old-enddatetime-displayed">
                          {" "}
                          <p style={{ color: "#8AC53F" }}>
                            {" "}
                            <span>
                              {this.state.endTime &&
                                this.MakeTimePretty2(this.state.endTime)}
                            </span>{" "}
                            <span style={{ marginLeft: 10 }}>
                              {" "}
                              <button
                                className="change-button"
                                type="button"
                                onClick={this.updateTheEndDateTime}>
                                Change
                              </button>
                            </span>
                          </p>
                        </div>
                        {this.state.updateTheEndDateTime ? (
                          <div className="good-ol-old-time-date-selector">
                            <DateTime
                              minDate={moment().toDate()}
                              onChange={this.ChangeDateEnd}
                            />
                            {/* <DateTime type="time"   onChange={this.ChangeTimeEnd}/>  */}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="create-post-datetimeend">
                        <DateTime
                          minDate={moment().toDate()}
                          onChange={this.ChangeDateEnd}
                        />
                        {/* <DateTime type="time"   onChange={this.ChangeTimeEnd}/>  */}
                      </div>
                    )}
                  </Col>
                </FormGroup>

                {this.state.dateErrors ? (
                  <p style={{ color: "red", padding: "15px 0" }}>
                    This must be a minimum of 1 day and End date cannot be
                    before the Start date
                  </p>
                ) : null}
                <FormGroup row style={{ marginTop: 40 }}>
                  <Col sm={12}>
                    <Label for="itemComments">Comments</Label>
                    <Input
                      value={this.state.comments}
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      }
                      type="textarea"
                      name="comments"
                      id="itemComments"
                      placeholder="Comments"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12} className="py-4">
                    <h4 className="pb-3">
                      Pickup or Delivery*{" "}
                      <span
                        style={{
                          color: "#a3a3a3",
                          fontWeight: "300",
                          fontSize: 19,
                        }}>
                        ( You must select at least one option )
                      </span>
                    </h4>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Col sm={7} className="pl-0">
                      <h4 className="post-title">
                        Renters can pick up my item{" "}
                      </h4>
                    </Col>
                    <Col
                      sm={5}
                      className="d-flex flex-row justify-content-between">
                      <Label for=""> </Label>
                      <label>
                        <Switch
                          onChange={this.handleChange}
                          checked={this.state.isPickupAvailable}
                          offColor="#E6E6E6"
                          onColor="#D0E7B2"
                          onHandleColor="#8AC53F"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                        />
                      </label>
                    </Col>
                  </Col>
                </FormGroup>
                {this.state.isPickupAvailable ? (
                  <div>
                    <FormGroup row>
                      <span style={{ paddingLeft: "15px", color: "#a3a3a3" }}>
                        This is the address that renters receive so they can
                        come pick up the equipment.
                        <br />
                        Please Select your address from the suggested
                        autocomplete for accuracy.
                      </span>
                    </FormGroup>

                    {this.state.editingmyown &&
                    !this.state.updatePickupAddress ? (
                      <div className="the-outer-cont">
                        <div style={{ display: "flex", padding: "30px 0" }}>
                          <div style={{ width: "50%" }}>
                            {" "}
                            <p>{this.state.pickupLocationAddress}</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "flex-end",
                            }}>
                            {" "}
                            <p>
                              <button
                                type="button"
                                className="change-button"
                                onClick={this.updateThepickupAddress}>
                                Change Pickup Address
                              </button>
                            </p>
                          </div>
                        </div>
                        {this.state.updatePickupAddress ? (
                          <div
                            className="my-map-container"
                            style={{ position: "relative" }}>
                            <Map
                              theaddress={this.theaddress}
                              // theaddress={this.state.pickupLocationAddress}
                              getlatlong={this.getlatlong}
                              center={{ lat: 51.05011, lng: -114.08529 }}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="my-map-container"
                        style={{ position: "relative" }}>
                        <Map
                          theaddress={this.theaddress}
                          // theaddress={this.state.pickupLocationAddress}
                          getlatlong={this.getlatlong}
                          center={{ lat: 51.05011, lng: -114.08529 }}
                        />
                      </div>
                    )}
                  </div>
                ) : null}

                <FormGroup row>
                  <Col sm={12}>
                    <Col sm={7} className="pl-0">
                      <h4 className="post-title">
                        I can deliver item to renter
                      </h4>
                    </Col>
                    <Col
                      sm={5}
                      className="d-flex flex-row justify-content-between">
                      <Label for=""> </Label>
                      <label>
                        <Switch
                          onChange={this.handleChange2}
                          checked={this.state.isDeliveryAvailable}
                          offColor="#E6E6E6"
                          onColor="#D0E7B2"
                          onHandleColor="#8AC53F"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                        />
                      </label>
                    </Col>
                  </Col>
                </FormGroup>
                {this.state.isDeliveryAvailable ? (
                  <div>
                    <FormGroup row>
                      <span style={{ paddingLeft: "15px", color: "#a3a3a3" }}>
                        Please type your full Delivery address and select it
                        from the dropdown below.{" "}
                      </span>
                    </FormGroup>

                    {this.state.editingmyown &&
                    !this.state.updateDeliveryAddress ? (
                      <div className="the-outer-cont">
                        <div style={{ display: "flex", padding: "30px 0" }}>
                          <div style={{ width: "50%" }}>
                            {" "}
                            <p>{this.state.deliveryLocationAddress}</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "flex-end",
                            }}>
                            {" "}
                            <p>
                              <button
                                type="button"
                                className="change-button"
                                onClick={this.updateTheDeliveryAddress}>
                                Change Delivery Address
                              </button>
                            </p>
                          </div>
                        </div>
                        {this.state.updateDeliveryAddress ? (
                          <div
                            className="my-map-container"
                            style={{ position: "relative" }}>
                            <Map
                              theaddress={this.theaddress}
                              // theaddress={this.state.pickupLocationAddress}
                              getlatlong={this.getlatlong}
                              center={{ lat: 51.05011, lng: -114.08529 }}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className="my-map-container"
                        style={{ position: "relative" }}>
                        <Map
                          theaddress={this.theaddressDeliver}
                          // theaddress={this.state.deliveryLocationAddress}
                          getlatlong={this.getlatlongDeliver}
                          center={{ lat: 51.05011, lng: -114.08529 }}
                        />
                      </div>
                    )}

                    <FormGroup row>
                      <Col>
                        <h4>Delivery Rate</h4>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={6}>
                        <Label for="itemDeliveryFlat">
                          Flat Rate, first 10 km
                        </Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            $
                          </InputGroupAddon>
                          <Input
                            value={this.state.deliveryRateFirst10Km}
                            onChange={(e) =>
                              this.setState({
                                deliveryRateFirst10Km: parseInt(e.target.value),
                              })
                            }
                            min={0}
                            type="number"
                            step="1"
                            name="deliveryFlat"
                            id="itemDeliveryFlat"
                          />
                          {/* <InputGroupAddon addonType="append">.00</InputGroupAddon> */}
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <Label for="itemDeliveryExtra">Extra km</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            $
                          </InputGroupAddon>
                          <Input
                            value={this.state.deliveryRateExtraPerKm}
                            onChange={(e) =>
                              this.setState({
                                deliveryRateExtraPerKm: parseInt(
                                  e.target.value
                                ),
                              })
                            }
                            min={0}
                            type="number"
                            step="1"
                            name="deliveryExtra"
                            id="itemDeliveryExtra"
                          />
                          {/* <InputGroupAddon addonType="append">.00</InputGroupAddon> */}
                        </InputGroup>
                      </Col>
                    </FormGroup>
                  </div>
                ) : null}

                <hr />
                <FormGroup>
                  <div
                    className="rental-agreement-section"
                    style={{ color: "rgb(163, 163, 163)", marginTop: 30 }}>
                    <p>
                      Rental Agreement used for this post is :
                      <span>{this.state.rentalpdfname}</span>
                    </p>
                    <p>
                      Go to{" "}
                      <Link to="/settings" style={{ color: "#8AC53F" }}>
                        settings
                      </Link>{" "}
                      if you want to see the Default Rental Agreement or to
                      upload your own Rental agreement.
                    </p>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div style={{ minHeight: "50px" }}>
                    {this.state.fielderrors ? (
                      <p style={{ color: "red" }}>
                        You must fill out all the mandotory * feilds! Please
                        double check you didnt miss anything{" "}
                      </p>
                    ) : null}

                    {this.state.apierrors.length > 2 ? (
                      <p style={{ color: "red" }}>{this.state.apierrors} </p>
                    ) : null}
                  </div>
                </FormGroup>

                {this.state.editingmyown ? (
                  <Button
                    type="button"
                    onClick={this._handlePostUpdate}
                    className="edit-post-submit-btn submit-button py-3">
                    Update Post
                  </Button>
                ) : (
                  <Button
                    type="button"
                    onClick={this._handleSubmit}
                    className="edit-post-submit-btn submit-button py-3">
                    Post
                  </Button>
                )}
              </Form>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }
}

EditPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.number,
  postUserId: PropTypes.number,
};

export default EditPost;
